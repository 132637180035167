(function (Application, $, window, undefined) {

  Application.behaviors.setupApp = function (context) {
    var i = 0,
      $divs = $('div.section-imagery > div', context);

    if ($divs.length < 2) {
      return;
    }

    $divs.not(':first').addClass('hidden');
    function cycle() {
      i = ++i % $divs.length; // increment i,
      // and reset to 0 when it equals divs.length
      $divs.eq(i).removeClass('hidden');
      $divs.not(':eq(' + i + ')').addClass('hidden');
    };

    window.setInterval(function () {
      cycle();
    }, 5000);
  };

}(window.Application, jQuery, window));

